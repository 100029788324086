<template>
  <div>
    <div class="wrap" v-if="platform.isPC">
      <div class="topbox">
        <ul>
          <img :src="info.logoUrl" alt="" />
        </ul>
        <ul>
          <p class="title">{{ $t("index.关于牧德") }}</p>
          <li @click="goto('/aboutus?view=0')">
            {{ $t("index.公司介绍") }}
          </li>
          <li @click="goto('/aboutus?view=1')">
            {{ $t("index.企业文化") }}
          </li>
          <li @click="goto('/aboutus?view=2')">
            {{ $t("index.发展历程") }}
          </li>
        </ul>
        <ul>
          <p class="title">{{ $t("index.媒体中心") }}</p>
          <li>{{ $t("index.新闻资讯") }}</li>
          <li>{{ $t("index.产品视频") }}</li>
          <li>{{ $t("index.使用案例") }}</li>
        </ul>
        <ul>
          <p class="title">{{ $t("index.服务与支持") }}</p>
          <li>{{ $t("index.售后指南") }}</li>
          <li>{{ $t("index.服务咨询") }}</li>
          <li @click="goto('/service/download/app')">
            {{ $t("index.软件下载") }}
          </li>
          <li>{{ $t("index.说明书") }}</li>
        </ul>
        <ul>
          <p class="title">{{ $t("index.联系我们") }}</p>
          <li>
            <i class="el-icon-phone"></i>
            <div>
              {{ info.contact }}
            </div>
          </li>
          <li v-if="false">
            <img src="@assets/imgs/spidertwoexplainM/address_icon.png" />
            {{ $t("index.北京市，丰台区，汉威国际4区7号楼8层") }}
          </li>
          <li>
            <i class="el-icon-message"></i>
            <div>
              {{ info.email }}
            </div>
          </li>
          <li>
            <div class="wximage">
              <img
                src="@assets/imgs/footerM-icon/wechat-fill.png"
                class="image"
              />
              <img :src="info.wxUrl" alt="" class="wxcode" />
            </div>
            <img
              src="@assets/imgs/footerM-icon/facebook-fill.png"
              class="image"
            />
            <img
              src="@assets/imgs/footerM-icon/instagram-fill.png"
              class="image"
            />
          </li>
        </ul>
      </div>
      <p
        style="
          text-align: center;
          position: relative;
          top: 20px;
          font-size: 14px;
        "
        v-if="isCN"
      >
        Copyright © 牧德科技. 2024 - All Rights Reserved. 京ICP备18012722号-1
      </p>
    </div>
    <div class="mwrap" v-else>
      <div :class="['nav-item', { active: spreadNavIdx === 7 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(7)">
          <span>{{ $t("index.热门产品") }}</span>
          <i></i>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 7 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(7)">
          <span>{{ $t("index.软件中心") }}</span>
          <i></i>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 7 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(7)">
          <span>{{ $t("index.新闻资讯") }}</span>
          <i></i>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 7 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(7)">
          <span>{{ $t("index.服务与支持") }}</span>
          <i></i>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 7 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(7)">
          <span>{{ $t("index.关于我们") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '7_1' }]"
            @click="onNavClick(7, 1)"
          >
            {{ $t("index.下载") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '7_2' }]"
            @click="onNavClick(7, 2)"
          >
            {{ $t("index.下载") }}
          </div>
        </div>
      </div>
      <div :class="['nav-item']">
        <div class="nav-item-content" @click="onNavClick(1)">
          <span> {{ $t("index.合作申请") }}</span>
        </div>
      </div>
      <div class="popover" v-if="false">
        <div v-if="!isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible1"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@Gowow Dirt Ebike</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/facebook-fill.png"
              @click="visible1 = !visible1"
              slot="reference"
            />
          </el-popover>
        </div>
        <div v-if="!isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible2"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@GOWOWMOTO</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/instagram-fill.png"
              @click="visible2 = !visible2"
              slot="reference"
            />
          </el-popover>
        </div>
        <div v-if="isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible5"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@格物Gowow</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/wechat-fill.png"
              @click="visible5 = !visible5"
              slot="reference"
            />
          </el-popover>
        </div>
        <div v-if="isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible6"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@Gowow格物官方微博</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/weibo-circle-fill.png"
              @click="visible6 = !visible6"
              slot="reference"
            />
          </el-popover>
        </div>

        <div>
          <el-popover
            placement="top"
            width="auto"
            v-model="visible3"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">Service@gowow.cn</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/mail-fill.png"
              @click="visible3 = !visible3"
              slot="reference"
            />
          </el-popover>
        </div>
        <div>
          <el-popover
            placement="top"
            width="auto"
            v-model="visible4"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">+86-10-63724080</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/phone-fill.png"
              @click="visible4 = !visible4"
              slot="reference"
            />
          </el-popover>
        </div>
      </div>
      <ul class="callme">
        <li class="wetitle">{{ $t("index.联系我们") }}</li>
        <li class="small">
          <img src="@assets/imgs/spidertwoexplainM/phone_icon.png" />
          +86-10-86781888
        </li>
        <li class="small">
          <img src="@assets/imgs/spidertwoexplainM/enterprise_icon .png" />
          {{ $t("index.周一至周日") }}
          :9:00-18:30
        </li>
        <li class="small">
          <img src="@assets/imgs/spidertwoexplainM/address_icon.png" />
          {{ $t("index.北京市，丰台区，汉威国际4区7号楼8层") }}
        </li>
        <li class="small">
          <img src="@assets/imgs/spidertwoexplainM/mail_icon.png" />
          cscentre@modehoist.com
        </li>
        <br v-if="isCN" />
        <li class="wetitle" v-if="isCN">微信公众号</li>
        <li v-if="isCN">
          <img src="@assets/imgs/spidertwoexplainM/QR code.png" />
        </li>
        <br v-if="isCN" />
        <li v-if="isCN" class="small">第一时间获得最新动态</li>
        <br />
        <li class="relation">
          <img
            v-if="isCN"
            src="@assets/imgs/spidertwoexplainM/WeChat_icon.png"
          />
          <img src="@assets/imgs/spidertwoexplainM/whatsapp_icon.png" />
          <img src="@assets/imgs/spidertwoexplainM/facebook_icon.png" />
          <img src="@assets/imgs/spidertwoexplainM/ins_icon.png" />
        </li>
      </ul>
      <div v-if="isCN">
        <div class="copyrightm">
          <p>Copyright © 牧德科技. 2024 - All Rights Reserved.</p>
          <p>京ICP备18012722号 隐私声明</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import { mapState } from "vuex";
import platform from "@utils/platform";
import RouteMap, { activeRoute } from "./Header/RouteMap";
import { getInfo } from "@/service/api";

export default {
  computed: {
    ...mapState(["lang"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  data() {
    return {
      info: {},
      platform,
      curNav: activeRoute,
      spreadNavIdx: 0,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
    };
  },
  mounted() {
    this.Info();
  },
  methods: {
    goto,
    async Info() {
      this.info = (await getInfo()) || {};
      // console.log(this.info);
    },
    setSpreadNavIdx(idx) {
      console.log(idx);
      //  2暂时没有菜单
      // if ([2].includes(idx)) return;
      // if (idx === this.spreadNavIdx) {
      //   this.spreadNavIdx = "";
      // } else {
      //   this.spreadNavIdx = idx;
      // }
    },
    onNavClick(navIdx, nav2Idx = 0) {
      this.curNav = `${navIdx}_${nav2Idx}`;
      const url = RouteMap[this.curNav];
      console.log(url);
      // if (url) {
      //   goto(url);
      // }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

img {
  vertical-align: top;
}

.copyrightm {
  background-color: #fff;
  padding: px2rem(10px) px2rem(20px);
  font-size: px2rem(11px);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #101010;
  line-height: px2rem(18px);
  text-transform: uppercase;
  border: none;
}
.mwrap {
  font-size: 0;
  width: 100vw;
  height: auto;
  padding: 0 px2rem(20px) px2rem(20px) px2rem(20px);
  background-color: #343434;
  color: white;

  .popover {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: px2rem(20px) 0 px2rem(5px);
    div {
      width: 15%;

      img {
        width: 100%;
      }
    }
  }
  .nav-item {
    // margin-top: px2rem(5px);
    // line-height: px2rem(70px);
    width: 100%;
    // 展开
    &.active {
      height: auto;
      .sub-nav {
        display: block;
        border: none;
      }
    }
    .nav-item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: px2rem(15px);
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: 400;
      line-height: px2rem(40px);
      height: px2rem(60px);
      // padding-bottom: px2rem(3px);
      border-bottom: px2rem(1px) solid #9f9f9f;
      i {
        width: px2rem(25px);
        height: px2rem(25px);
        background: url(@assets/imgs/spidertwoexplainM/develop_icon.png)
          no-repeat center;
        background-size: 60%;
      }
    }
    .sub-nav {
      display: none;
      // padding-bottom: px2rem(20px);
      font-size: px2rem(14px);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      // line-height: px2rem(20px);
      .sub-nav-item {
        font-size: px2rem(16px);
        font-family: PingFang-SC-Bold, PingFang-SC;
        line-height: px2rem(30px);
        margin-top: px2rem(8px);
        // height: px2rem(50px);
        &.active {
          color: #e8e354;
        }
        &:nth-child(n + 2) {
          font-weight: 400;
        }
      }
    }
  }
  .callme {
    text-align: center;
    font-size: px2rem(12px);

    .wetitle {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin: px2rem(10px) 0;
    }
    .small {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
      margin: px2rem(5px);

      img {
        width: px2rem(14px);
        // margin-top: px2rem(20px);
      }
    }
    .relation {
      margin: px2rem(5px) 0;
      img {
        margin: 0 px2rem(7px);
      }
    }
  }
}
.wrap {
  background-color: rgba($color: #000000, $alpha: 1);
  padding: 40px 100px;
  color: #fff;
  position: relative;
  z-index: 10;
  .topbox {
    position: relative;
    display: flex;
    justify-content: space-between;
    ul {
      max-width: 200px;
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin: 10px 0;
        position: relative;
        // right: 14px;
        i {
          margin-right: 10px;
          color: red;
        }
        .image {
          width: 26px;
          margin: 10px 10px;
        }
        .wximage:hover {
          .wxcode {
            display: block;
          }
        }
        .wximage {
          position: relative;
          .wxcode {
            width: 46px;
            position: absolute;
            top: -45px;
            left: 0;
            display: none;
            // transform: translate(50%, 50%);
          }
        }
        img:hover {
          opacity: 0.6;
        }
      }
      li:hover {
        color: red;
        cursor: pointer;
      }
    }
    .title {
      // padding-left: 5px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 60px;
    }
  }
}
</style>
