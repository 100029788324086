import Vue from 'vue';

import {
  AddressList,
  Icon,
  Form,
  Field,
  Popup,
  Overlay,
  Button,
  Dialog,
  Radio,
  RadioGroup,
  Area,
  Toast,
  Picker,
  ActionSheet 
} from 'vant';
import '@vant/touch-emulator';

import 'vant/lib/address-list/style';
import 'vant/lib/icon/style';
import 'vant/lib/form/style';
import 'vant/lib/field/style';
import 'vant/lib/popup/style';
import 'vant/lib/overlay/style';
import 'vant/lib/button/style';
import 'vant/lib/dialog/style';
import 'vant/lib/radio-group/style';
import 'vant/lib/radio/style';
import 'vant/lib/area/style';
import 'vant/lib/toast/style';
import 'vant/lib/picker/style';
import 'vant/lib/action-sheet/style';

Vue.use(AddressList);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Overlay);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Area);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(ActionSheet);
