import Vue from 'vue';
import VueI18n from 'vue-i18n';
import znCH from './zn-CH';
import enUS from './en-US';
// element 国际化
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementLocale from 'element-ui/lib/locale';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'zh-CN',
  messages: {
    'zh-CN': {
      ...znCH,
      ...zhLocale,
    },
    'en-US': {
      ...enUS,
      ...enLocale,
    },
  },
});

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n;
