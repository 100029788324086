import axios from '@utils/axios';
import _ from 'lodash';
const SUCESESS_CODE = [0, 200];
// const isDev = process.env.NODE_ENV === 'development';

// export * from './api2'

// 解析结果
export const resolve = fetchFn =>
  _.wrap(fetchFn, async (fetchFn, params) => {
    const res = await fetchFn(params);
    const { code, msg, data, message } = res || {};
    if (SUCESESS_CODE.includes(+code)) {
      return data;
    }
    throw msg || message;
  });

/**
 * app接口更新
 */
// app名称 product
// 0=牧德控制器
// 1=

// 系统 type
// 0=安卓
// 1=iOS

/**
 * App更新
 */
export const app_update = resolve(params => {
  // const url = '/api/mode/app/update';
  const url = '/mode/app/update';
  return axios.get(url, { params });
});
/**
 * 产品说明书详情
 */
export const manual_find = resolve(params => {
  // const url = '/api/manual/find';
  const url = '/V1/manual/find';
  return axios.get(url, { params });
});
/**
 * 获取说明书列表
 */
export const selectManuals = resolve(params => {
  const url = '/V1/manual/selectManuals';
  return axios.get(url, { params });
});

/**
 *调查问卷接口
 */
export const survey_search = resolve(params => {
  // const url = '/api/survey/search';
  const url = '/V1/survey/search';
  return axios.get(url, { params });
});
export const survey_submit = resolve(params => {
  // const url = '/api/survey/submit';
  const url = '/V1/survey/submit';
  return axios.post(url, { params });
});
/**
 * 获取语言
 */
export const getLang = resolve(params => {
  const url = '/V1/common/getLanguage';
  return axios.get(url, { params });
});
/**
 * 获取菜单
 */
export const getMenu = resolve(params => {
  const url = '/V1/website/getMenu';
  return axios.get(url, { params });
});
/**
 * 获取Banner
 */
export const getBanner = resolve(params => {
  const url = '/V1/website/getBanner';
  return axios.get(url, { params });
});
/**
 * 获取行业应用
 */
export const getIndustryApplication = resolve(params => {
  const url = '/V1/website/getIndustryApplication';
  return axios.get(url, { params });
});
/**
 * 获取官网基本信息
 */
export const getInfo = resolve(params => {
  const url = '/V1/website/getInfo';
  return axios.get(url, { params });
});
/**
 * 获取热销产品
 */
export const getHotProduct = resolve(params => {
  const url = '/V1/website/getHotProduct';
  return axios.get(url, { params });
});
/**
 * 获取首页新闻动态
 */
export const selectNews = resolve(params => {
  const url = '/V1/media/selectNews';
  return axios.get(url, { params });
});
/**
 * 获取首页视频列表
 */
export const selectVideo = resolve(params => {
  const url = '/V1/media/selectVideo';
  return axios.get(url, { params });
});
/**
 * 获取产品详情
 */
export const getProduct = resolve(params => {
  const url = '/V1/website/getProduct';
  return axios.get(url, { params });
});
/**
 * 获取公司资质
 */
export const getFirmAptitude = resolve(params => {
  const url = '/V1/website/getFirmAptitude';
  return axios.get(url, { params });
});
/**
 * 获取公司基本信息
 */
export const getFirmInfo = resolve(params => {
  const url = '/V1/website/getFirmInfo';
  return axios.get(url, { params });
});




//用户数据
/**
 * data: {password , username}
 */
export const login = resolve(data => {
  const url = '/api/user/login';
  return axios.post(url, data);
});

export const logout = resolve(data => {
  const url = '/api/user/logout';
  return axios.post(url, data);
});
/**
 * data: {code , email , password , phonenumber}
 */
export const register = resolve(data => {
  const url = '/api/user/register';
  return axios.post(url, data);
});
/**
 * 修改密码
 * data: {code , email , password , phonenumber}
 */
export const changePassword = resolve(data => {
  const url = '/api/user/password/change';
  return axios.post(url, data);
});
/**
 * res data :{accessToken}
 */
export const refreshToken = resolve(params => {
  const url = '/api/user/refreshToken';
  return axios.get(url, { params });
});

/**
 * 获取验证码 phonenumber ｜ email
 */
export const verificationCode = resolve(data => {
  const url = '/api/user/send/verificationCode';
  return axios.post(url, data);
});

export const check_phonenumber = resolve(data => {
  const url = '/api/user/check/phonenumber';
  return axios.post(url, data);
});

export const check_email = resolve(data => {
  const url = '/api/user/check/email';
  return axios.post(url, data);
});

/**
 * 获取骑手资料
 */
export const getProfile = resolve(params => {
  const url = '/api/user/profile';
  return axios.get(url, { params });
});

/**
 * 更新骑手资料
 */
export const updateProfile = resolve(data => {
  const url = '/api/user/profile';
  return axios.post(url, data);
});

